import React, {useEffect, useState} from 'react'
import useToken from '../../components/hooks/useToken';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import Header from '../../components/common/Header';
import Login from '../../components/Login';
import CustomerListFromSubtaskCount from './CustomerListFromSubtaskCount'

import { CustomerApi, StatisticsApi } from '../../apis/CustomerApi';

function StatisticsApp () {
  const [filters, setFilters] = useState({ dateFrom: new Date(), dateTo: new Date() })
  const [stats, setStats] = useState([])
  const { token, setToken } = useToken()

  // useEffect(() => {
  //   console.log(stats)
  // }, [stats])

  if(!token) {
    return (<Login setToken={setToken} />)
  }

  const onApplyFilters = async function() {
    setStats(await StatisticsApi.getStatistics(filters))
  }

  return (
    <>
      <Header></Header>
      <Container className='mt-5'>
        <Row>
          <Col>
            <Form>
              <Card>
                <Card.Header>
                  Ερώτημα
                </Card.Header>
                <Card.Body>
                  <Form.Group as={Row} className="mb-0" controlId="formDates">
                    <Form.Label column sm={2}>Από</Form.Label>
                    <Col sm={4}>
                      <DatePicker selected={filters.dateFrom} showicon dateFormat={"d/M/yyyy"}
                        onChange={(e) => setFilters((p) => ({...p, dateFrom: e}))}></DatePicker>
                    </Col>
                    <Form.Label column sm={2}>Έως</Form.Label>
                    <Col sm={4}>
                      <DatePicker selected={filters.dateTo} showicon dateFormat={"d/M/yyyy"}
                        onChange={(e) => setFilters((p) => ({...p, dateTo: e}))}></DatePicker>
                    </Col>
                  </Form.Group>
                </Card.Body>
                <Card.Body>
                
                  <Form.Group as={Row} className="mb-3" controlId="formCompany">
                    <Form.Label column sm={2}>Εταιρεία</Form.Label>
                    <Col sm={4}>
                      <Form.Select defaultValue={filters.referralId}
                        onChange={(e) => setFilters(p => ({...p, referralId: +e.target.value}))}>
                        <option key={0} value={null}>Όλες</option>
                        <option key={1} value={1}>Cool & Hot</option>
                        <option key={2} value={2}>11888</option>
                        <option key={3} value={3}>24 Ώρες</option>
                        <option key={4} value={4}>Internet</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formServiceCrew">
                    <Form.Label column sm={2}>Συνεργείο</Form.Label>
                    <Col sm={4}>
                      <Form.Select defaultValue={filters.serviceCrewId}
                        onChange={(e) => setFilters(p => ({...p, serviceCrewId: +e.target.value}))}>
                        <option key={0} value={null}>Όλα</option>
                        <option key={1} value={1}>ΘΩΜΑΣ</option>
                        <option key={2} value={2}>ΣΕΡΓΙΟΣ</option>
                        <option key={3} value={3}>ΚΩΣΤΑΣ</option>
                        <option key={4} value={4}>ΚΑΣΙΔΙΑΡΑΚΗΣ</option>
                        <option key={5} value={5}>ΠΑΝΤΕΛΗΣ</option>
                        <option key={6} value={6}>ΔΗΜΗΤΡΗΣ</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                  <Button variant="primary" className="mx-1" onClick={() => onApplyFilters()}>Εφαρμογή</Button>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>
        
        <Row className='mt-5'>
          <Col>
            <Card>
              <Card.Header>
                Στατιστικά
              </Card.Header>
              <Card.Body>
                <table cellPadding={2}>
                  <tr>
                    <td valign='top'>Ραντεβού: </td>
                    <td align='right' className='ps-4'>
                      <strong>{stats.length}<br />
                      {stats.filter(stat => stat.subtaskCount == 0 && stat.price == null).length} χωρίς εργασία</strong>
                    </td>
                  </tr>
                  <tr>
                    <td valign='top'>Μηχανήματα: </td>
                    <td align='right' className='ps-4'>
                      <strong>{stats.reduce((acc, row) => { return acc + row.subtaskCount }, 0)} <br/>
                      {stats.filter(stat => stat.subtaskServiceInfos?.filter(i => i.serviceKindId == 5).length > 0).length} φίλτρα</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Τζίρος: </td>
                    <td align='right' className='ps-4'><strong>{stats.reduce((acc, row) => { return acc + row.price }, 0).toLocaleString('el-gt')} &euro;</strong></td>
                  </tr>
                </table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <Card>
              <Card.Header>
                Πελάτες με κενές εργασίες
              </Card.Header>
              <Card.Body>
                <CustomerListFromSubtaskCount customers={stats.filter(stat => stat.subtaskCount == 0 && stat.price == null)}></CustomerListFromSubtaskCount>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default StatisticsApp;