import React from 'react';
import { api } from './BaseApi';

export const CustomerApi = {
    
    getAll: async function () {
        const response = await api.request({
            url: `/customers`,
            method: "GET"
        })
        return response.data
        // const resp = await fetch(`https://localhost:7002/Customers`, {
        //     method:'GET'
        // })
        // return await resp.json();
    },
    get: async function (id) {
        const response = await api.request({
            url: `/customers/${id}`,
            method: "GET"
        })
        return response.data
        // const resp = await fetch(`https://localhost:7002/Customers/${id}`, {
        //     method:'GET',
        //     headers: { 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJDb29sSG90U2VydmljZUFwcEFjY2Vzc1Rva2VuIiwianRpIjoiNDJhMTAyYjUtOWE4NC00NTQ5LWIzZDMtZmViNzA1MjcxMTAxIiwiaWF0IjoiMjYvMi8yMDIzIDU6NDM6MzAgzrzOvCIsIlVzZXJJZCI6IjAiLCJVc2VyTmFtZSI6InVzZXIiLCJFbWFpbCI6Im5vYm9keUBnZXRvbmxpbmUuZ3IiLCJleHAiOjE2Nzc0MzQwMTAsImlzcyI6Imh0dHBzOi8vY29vbGhvdGFwcC5nZXRvbmxpbmUuZ3IvIiwiYXVkIjoiaHR0cHM6Ly9jb29saG90YXBwLmdldG9ubGluZS5nci8ifQ.2ikC37u2_m1ojOor9fZ00VTEUmnCe102nT4KHUFFm5k` },
        // })
        // return await resp.json();
    },
    update: async function (id, data) {
        const response = await api.request({
            url: `/customers/${id}`,
            method: "PUT",
            data: data
        })
        return response.data
        // const resp = await fetch(`https://coolhotapp.getonline.gr/api/v1/Customers/${id}`, {
        //     method: 'PUT',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(data)
        // })
        // return await resp.json();
    },
    create: async function (data) {
        const response = await api.request({
            url: `/customers/`,
            method: "POST",
            data: data
        })
        return response.data;
        // const resp = await fetch(`https://coolhotapp.getonline.gr/api/v1/Customers`, {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(data)
        // })
        // return await resp.json();
    },
    getReferrals: async function() {
        return [
        { referralId: 1, name: 'Cool & Hot' },
        { referralId: 2, name: '11888' },
        { referralId: 3, name: '24 Ώρες' },
        { referralId: 4, name: 'Internet' },
        { referralId: 5, name: 'Praktiker' }]
    }
    
    // Equipment

    // Equipment Kind
};

export const CustomerEquipmentApi = {

    getEquipmentKinds: async function () {
        const response = await api.request({
            url: `/customers/equipment/kinds`,
            method: "GET"
        })
        return response.data;
        // const resp = await fetch(`https://localhost:7002/Customers/Equipment/Kinds`, {
        //     method:'GET'
        // })
        // return await resp.json();
    }
};

export const UserApi = {
    login: async function (data) {
        const response = await api.request({
            url: `/authentication/login/`,
            method: "POST",
            data: data
        })
        return response.data;
        // const resp = await fetch(`https://coolhotapp.getonline.gr/api/v1/Authentication/Login`, {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        //     body: JSON.stringify(data)
        // })
        // return await resp.json();
    }
}

export const ReminderApi = {
    getByMonth: async function (month) {
        const response = await api.request({
            url: `/reminders/on/${month}`,
            method: "GET"
        })
        return response.data;
    },
    getDue: async function () {
        const response = await api.request({
            url: `/reminders/due`,
            method: "GET"
        })
        return response.data;
    },
    get: async function (id) {
        const response = await api.request({
            url: `/reminders/${id}`,
            method: "GET"
        })
        return response.data;
    },
    getByCustomerTaskId: async function (id) {
        const response = await api.request({
            url: `/reminders/byCustomerTask/${id}`,
            method: "GET"
        })
        console.log(response.data)
        return response.data;
    },
    createOrUpdate: async function (reminder) {
        const response = await api.request({
            url: `/reminders/`,
            method: "POST",
            data: reminder
        })
        return response.data;
    }
}

export const StatisticsApi = {

    getStatistics: async function(filters) {
        const response = await api.request({
            url: `/statistics/`,
            method: "POST",
            data: filters
        })
        return response.data;
    }
}

export const ServiceApi = {

    getServiceCrews: async function() {
        return [
            { serviceCrewId: 1, name: 'ΘΩΜΑΣ' },
            { serviceCrewId: 2, name: 'ΣΕΡΓΙΟΣ' },
            { serviceCrewId: 3, name: 'ΚΩΣΤΑΣ' },
            { serviceCrewId: 4, name: 'ΚΑΣΙΔΙΑΡΑΚΗΣ' },
            { serviceCrewId: 5, name: 'ΠΑΝΤΕΛΗΣ' },
            { serviceCrewId: 6, name: 'ΔΗΜΗΤΡΗΣ' },
        ]
    },

    getServiceKinds: async function() {
        return [
            { serviceKindId: 1, name: 'Συντήρηση', equipmentKinds: [{ equipmentKindId: 1 }, {equipmentKindId: 2}] },
            { serviceKindId: 2, name: 'Εγκατάσταση', equipmentKinds: [{ equipmentKindId: 1 }] },
            { serviceKindId: 3, name: 'Απεγκατάσταση', equipmentKinds: [{ equipmentKindId: 1 }] },
            { serviceKindId: 4, name: 'Βιολογικός', equipmentKinds: [{ equipmentKindId: 1 }] },
            { serviceKindId: 5, name: 'Φίλτρα', equipmentKinds: [{ equipmentKindId: 1 }] },
            { serviceKindId: 6, name: 'Επισκευή', equipmentKinds: [{ equipmentKindId: 1 }, {equipmentKindId: 2}, {equipmentKindId: 3}] }
        ]
    }

}