import { useState } from 'react';
import Header from './components/common/Header';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import Login from './components/Login';
import useToken from './components/hooks/useToken';

import { Container, Row, Col, Card } from 'react-bootstrap';

import CustomerApp from './features/customer/CustomerApp';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencil, faTrash, faCheck, faTimes, faPlus, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faPencil, faTrash, faCheck, faTimes, faPlus, faExternalLinkAlt)



function App() {
  const [open,setOpen] = useState(false);
  const [currentCustomer,setCurrentCustomer] = useState({})

  const { token, setToken } = useToken();

  const onCloseModal = () => setOpen(false);

  function handleRowClick(row) {
    setCurrentCustomer(row.original);
    console.log(currentCustomer);
    setOpen(true);
  }

  if(!token) {
    return (
      <>
        <Login setToken={setToken} />
        <p>{token}</p>
      </>
      
    )
  }

  return (
    <>
      <Header></Header>
      <Container className='mt-5'>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                Πελατολόγιο
              </Card.Header>
              <CustomerApp></CustomerApp>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
