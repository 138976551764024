import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UserApi } from '../apis/CustomerApi'

async function loginUser(credentials) {
 return UserApi.login(credentials);
}

export default function Login({ setToken }) {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      userName: userName,
      password: password
    });
    console.log(token)
    setToken(token);
  }

  return(
    <div className="container my-5" style={{display: "flex", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <div className="login-wrapper">
        <h1>Please Log In</h1>
        <form onSubmit={handleSubmit}>
          <label>
            <p>Username</p>
            <input type="text" onChange={e => setUserName(e.target.value)} />
          </label>
          <label>
            <p>Password</p>
            <input type="password" onChange={e => setPassword(e.target.value)} />
          </label>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
