import { useState, useEffect } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  FilterFn,
  PaginationState,
  getPaginationRowModel
} from '@tanstack/react-table';
import Moment from 'react-moment';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DataTable(props) {
  const [data, setData] = useState(props.data)
  const [optionPreservePageIndex, setOptionPreservePageIndex] = useState(props.optionPreservePageIndex)
  const onAddClick = props.onAddClick
  const onRowClick = props.onRowClick
  
  const formatDate = function(datestring) {
    let val = new Date(datestring);
    return val;
  }

  // columns
  const columnHelper = createColumnHelper();
  const columns = props.columns.map(col => {
    return columnHelper.accessor(col.key, {
      id: col.key,
      header: col.header,
      cell: (celldata) => {
        if (col.format === 'date') {
          return formatDate(celldata.getValue()).toLocaleDateString('el-gr')
        }
        else if (col.format === 'check') {
          return celldata.getValue() === true ? <FontAwesomeIcon icon="check"></FontAwesomeIcon> : ''
        }
        else {
          return celldata.getValue() 
        }
        
      }
    })
  });

  useEffect(() => {
    console.log(table.getState().pagination.pageIndex)
      setData(props.data)
      //console.log("data update")
      //console.log(props.data)
  }, [props.data]);

  const [globalFilter, setGlobalFilter] = useState("");
  const globalFilterHandler = (e) => {
    setGlobalFilter(e.target.value);
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "includesString",
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const renderCell = function(row, cell) {
    if (cell.column.columnDef.accessorKey == "codes") {
      return null;
    }
    let openable = props.columns.find(col => col.key === cell.column.id)?.openable ? true : false
    let context = flexRender(cell.column.columnDef.cell, cell.getContext())
    
    if (openable) {
      return <td key={cell.id} className="clickable" onClick={() => onRowClick(row, cell.column.id)}>
        <a>{context} <FontAwesomeIcon icon="external-link-alt"></FontAwesomeIcon></a>
      </td>
    }
    
    return <td key={cell.id} onClick={() => onRowClick(row)}>{context}</td>
  }
       
  return (
    <>
      <table className='table'>
        <thead>
          { props.showSearch &&
          <tr>
            <td><div>
              <input
                value={globalFilter ?? ""}
                onChange={globalFilterHandler}
                className="p-2 font-lg shadow border border-block"
                placeholder="Search all columns..."
              />
            </div></td>
          </tr>
          }
          <tr>
            {props.columns.map(col => {
              if (col.width !== "0") {
                return <th key={col.key} width={col.width}>{col.header}</th>
              }})}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
          <tr key={row.id} className="clickable">
            
              {/*row.getVisibleCells().map(cell => (console.log(cell.column)))*/}
              {row.getVisibleCells().map(cell => {
                return renderCell(row, cell)
              })}
              <td></td>
          </tr>
          ))}
        </tbody>
      </table>
      
      <div className="h-2" />
      <div className="d-flex justify-content-between" style={{width: "100%", padding: "0 0.75rem 0.5rem"}}>
        <span className="flex">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </span>
        <span className="flex items-center gap-1">
          <span>Page</span> 
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <span>
          { props.showAddButton &&
          <button className="btn btn-primary" onClick={() => onAddClick()}>Προσθήκη Πελάτη</button>
          } 
        </span>
  
        {/* <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
      
      </div>

{/* <div>{table.getRowModel().rows.length} Rows</div>
<pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}

    </>
    );  
}

export default DataTable;