import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useToken from '../../components/hooks/useToken';
import { Container, Row, Col, Card } from 'react-bootstrap';

import Header from '../../components/common/Header';
import Login from '../../components/Login';
import CalendarList from './CalendarList';

function CalendarApp () {
  const { month } = useParams()
  const [ realDate, setRealDate ] = React.useState(null)
  const { token, setToken } = useToken();
  const monthNames = ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάΐος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"]

  React.useEffect(() => {
    console.log(month)
    setRealDate(new Date(parseInt(month.substring(0,4)), parseInt(month.substring(4), 10) -1, 1))
  }, [month])

  let navigate = useNavigate();

  async function nextMonth() {
    if (realDate?.getMonth() == 11) { navigate(`/calendar/${realDate?.getFullYear()+1}01`, {replace: true}) }
    navigate(`/calendar/${realDate?.getFullYear()}${String(realDate?.getMonth()+2).padStart(2, '0')}`, {replace: true})
  }

  async function prevMonth() {
    if (realDate?.getMonth() == 1) { navigate(`/calendar/${realDate?.getFullYear()-1}12`, {replace: true}) }
    navigate(`/calendar/${realDate?.getFullYear()}${String(realDate?.getMonth()).padStart(2, '0')}`, {replace: true})
  }


  if(!token) {
    return (<Login setToken={setToken} />)
  } 

  return (
    <>
      <Header></Header>
      <Container className='mt-5'>
        <Row className='mb-4'>
          <Col className='text-center'>
            <h3>
              <button className='btn btn-secondary btn-sm mx-2' onClick={(e) => prevMonth()}>&lt;</button>
              <span style={{minWidth: '250px', display: 'inline-block'}}>{((realDate!==null) ? monthNames[realDate?.getMonth()] + ' ' + realDate?.getFullYear() : '' )}</span>
              <button className='btn btn-secondary btn-sm mx-2' onClick={(e) => nextMonth()}>&gt;</button>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                Ημερολόγιο
              </Card.Header>
              <CalendarList month={month}></CalendarList>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CalendarApp;