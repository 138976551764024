import { useState, useEffect } from 'react';
import { Form,  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerEquipmentApi } from '../../apis/CustomerApi';
import _, { eq } from 'lodash';

function EquipmentList(props) {
  const [data, setData] = useState(props.data);
  const [rowEdited, setRowEdited] = useState({});
  const [rowAdded, setRowAdded] = useState(0);
  const [dataHasChange, setDataHasChange] = useState(false);
  const [dataEquipmentKinds, setDataEquipmentKinds] = useState([]);
  
  const onDataChanged = props.onDataChanged;
  const customerId = props.customerId;

  useEffect(() => {
    setData(props.data)
  }, [props.data]);

  useEffect(() => {
    if (dataHasChange) {
      onDataChanged(data)
      console.log(data)
    }
    setDataHasChange(false)
  }, [dataHasChange])

  useEffect(() => {
    async function getEquipmentKinds() {
      let eqkinds = await CustomerEquipmentApi.getEquipmentKinds()
      console.log('eqkinds')
      console.log(eqkinds)
      setDataEquipmentKinds(eqkinds)
    }
    getEquipmentKinds()
  }, [])

  const onRowEdit = function(id) {
    setRowEdited(_.cloneDeep(data.find(row => {
      return row.customerEquipmentId === id
    })))
  }

  const onRowAdd = function() {
    let minId = Math.min(...data.map(row => row.customerEquipmentId))
    if (minId < 0) { minId = minId-1 } else { minId = -1 }
    const newrow = { customerEquipmentId: minId, customerId: customerId, name: '', equipmentKindId: 1 }
    setData([...data, newrow])
    setRowAdded(minId)
    setRowEdited(newrow)
  };

  const onRowDelete = function(id) {
    setData(data.filter(f => f.customerEquipmentId !== id));
    setDataHasChange(true)
  }

  const onRowAccept = function() {
    setData(data.map((row) => {
      return row.customerEquipmentId === rowEdited.customerEquipmentId ? rowEdited : row;
    }));
    if (rowAdded !== 0) { setRowAdded(0) }
    setRowEdited({})
    // setData hasn't fired yet, use useEffect!
    setDataHasChange(true)
  }

  const onRowDiscard = function() {
    if (rowAdded !== 0) { 
      setData(data.filter(f => f.customerEquipmentId !== rowEdited.customerEquipmentId));
      setRowAdded(0) 
    }
    setRowEdited({})
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <th width="70%">Μηχάνημα</th>
          <th width="30%">Είδος</th>
          <th width="0%"></th>
        </tr>
      </thead>
      <tbody>
        {(data && data.length > 0 ) ? data.map(row => {
          if (row.customerEquipmentId === rowEdited.customerEquipmentId) {
            return (
              <tr key={row.customerEquipmentId}>
                <td>
                  <Form.Control type="text" value={rowEdited.name}
                    onChange={(e) => setRowEdited((p) => ({ ...p, name: e.target.value }))} />
                </td>
                <td>
                  <Form.Select value={rowEdited.equipmentKindId}
                    onChange={(e) => setRowEdited((p) => ({ ...p, equipmentKindId: +e.target.value }))}>
                    {dataEquipmentKinds?.map(kind => (
                      <option key={kind.equipmentKindId} value={kind.equipmentKindId}>{kind.name}</option>
                    ))}
                  </Form.Select>
                </td>
                <td nowrap="nowrap">
                  <button className="btn btn-sm btn-primary" onClick={onRowAccept}><FontAwesomeIcon icon="check"></FontAwesomeIcon></button>
                  <button className="btn btn-sm btn-danger" onClick={onRowDiscard}><FontAwesomeIcon icon="times"></FontAwesomeIcon></button>
                </td>
              </tr>
            )
          }
          else {
            return (
              <tr key={row.customerEquipmentId}>
                <td>{row.name}</td>
                <td>{dataEquipmentKinds?.find(e => e.equipmentKindId == row.equipmentKindId)?.name}</td>
                <td nowrap="nowrap">
                  <button className="btn btn-sm btn-light" onClick={() => onRowEdit(row.customerEquipmentId)}><FontAwesomeIcon icon="pencil"></FontAwesomeIcon></button>
                  <button className="btn btn-sm btn-light" onClick={() => onRowDelete(row.customerEquipmentId)}><FontAwesomeIcon icon="trash"></FontAwesomeIcon></button>
                </td>
              </tr>
            )
          }
        }) : (<tr className='d-none'></tr>)}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3} className="text-end">
            <button className="btn btn-sm btn-primary nowrap" onClick={onRowAdd}><FontAwesomeIcon icon="plus"></FontAwesomeIcon></button>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default EquipmentList;