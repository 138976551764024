import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Tabs, Tab, Form, Button, FloatingLabel, Badge } from 'react-bootstrap';
import _ from "lodash";
import EquipmentList from '../features/CustomerEquipment/EquipmentList';
import CustomerTaskList from "../features/customer/CustomerTaskList";

export default function CustomerDetail(props) {
    const [data, setData] = useState(props.customer)

    const onSaveClick = props.onSaveClick
    const onCancelClick = props.onCancelClick

    useEffect(() => {
        setData(props.customer);
        console.log(props.customer)
    }, [props.customer])

    useEffect(() => {
        if (_.isEqual(props.customer, data)) {
            console.log('data not changed');
        } else {
            console.log('data changed');
        }

    }, [data])

    function onEquipmentDataChanged(equipment) {
        const customer = _.cloneDeep(data);
        customer.equipment = equipment;
        setData(customer);
    }

    function onTasksDataChanged(tasks) {
        const customer = _.cloneDeep(data);
        customer.tasks = tasks;
        setData(customer);
    }


    return (
        <Card>
            <Card.Header>
                <h4 className="my-1">{data.fullName}</h4>
            </Card.Header>
            <Card.Body>
                <Row className="g-2 my-1">
                    <Col>
                        <FloatingLabel controlId="floatingFullName" label="Ονοματεπώνυμο">
                            <Form.Control type="text" placeholder="Ονοματεπώνυμο" value={data.fullName}
                                onChange={(e) => setData((p) => ({ ...p, fullName: e.target.value }))} />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="g-2 my-1">
                    <Col md={6}>
                        <FloatingLabel controlId="floatingAddressLine1" label="Διεύθυνση">
                            <Form.Control type="text" placeholder="Διεύθυνση" value={data.addressLine1}
                                onChange={(e) => setData((p) => ({ ...p, addressLine1: e.target.value }))} />
                        </FloatingLabel>
                    </Col>
                    <Col md={3}>
                        <FloatingLabel controlId="floatingCity" label="Πόλη">
                            <Form.Control type="text" placeholder="Πόλη" value={data.addressLine2}
                                onChange={(e) => setData((p) => ({ ...p, addressLine2: e.target.value }))} />
                        </FloatingLabel>
                    </Col>
                    <Col md={3}>
                        <FloatingLabel controlId="floatingPostalCode" label="ΤΚ">
                            <Form.Control type="text" placeholder="ΤΚ" value={data.postalCode}
                                onChange={(e) => setData((p) => ({ ...p, postalCode: e.target.value }))} />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="g-2 my-1">
                    <Col md={6}>
                        <FloatingLabel controlId="floatingPhoneNumber" label="Τηλέφωνο">
                            <Form.Control type="text" placeholder="Τηλέφωνο" value={data.phoneNumber}
                                onChange={(e) => setData((p) => ({ ...p, phoneNumber: e.target.value }))} />
                        </FloatingLabel>
                    </Col>
                    <Col md={6}>
                        <FloatingLabel controlId="floatingMobilePhoneNumber" label="Κινητό Τηλέφωνο">
                            <Form.Control type="text" placeholder="Κινητό Τηλέφωνο" value={data.mobilePhoneNumber}
                                onChange={(e) => setData((p) => ({ ...p, mobilePhoneNumber: e.target.value }))} />
                        </FloatingLabel>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Body>
                <Row className="g-2 my-1">
                    <Col md={4}>
                        <FloatingLabel controlId="floatingResellerId" label="Εταιρεία">
                            <Form.Select value={data.referralId}
                                onChange={(e) => setData((p) => ({ ...p, referralId: +e.target.value }))}>
                                <option key={0} value={null}></option>
                                <option key={1} value={1}>Cool & Hot</option>
                                <option key={2} value={2}>11888</option>
                                <option key={3} value={3}>24 Ώρες</option>
                                <option key={4} value={4}>Internet</option>
                                <option key={5} value={5}>Praktiker</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col md={4}>
                        <FloatingLabel controlId="floatingListId" label="Λίστα">
                            <Form.Select value={data.listId}
                                onChange={(e) => setData((p) => ({ ...p, listId: +e.target.value }))}>
                                <option key={0} value={null}></option>
                                <option key={1} value={1}>Συντήρηση Κλιματισμού</option>
                                <option key={2} value={2}>Επικσευή Κλιματισμού</option>
                                <option key={3} value={3}>Ηλιακός Θερμοσίφωνας</option>
                                <option key={4} value={4}>Οικιακή Συσκευή</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col md={4}>
                        <FloatingLabel controlId="floatingResellerCode" label="Κωδικός">
                            <Form.Control type="text" placeholder="Κωδικός" value={data.codes}
                                onChange={(e) => setData((p) => ({ ...p, codes: e.target.value }))} />
                        </FloatingLabel>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Body className="px-0">
                <Tabs>
                    <Tab eventKey="tasks" title="Εργασίες" style={{ minHeight: '200px' }}>
                        <Container className="px-3">
                        <Row className="g-2 my-1"><Col>
                            <CustomerTaskList customerId={data.customerId} data={data.tasks} equipment={data.equipment} onDataChanged={onTasksDataChanged}></CustomerTaskList>
                            </Col></Row></Container>
                    </Tab>
                    <Tab eventKey="inventory" title="Μηχανήματα" style={{ minHeight: '200px' }}>
                        <Container className="px-3"><Row className="g-2 my-1"><Col>
                            <EquipmentList customerId={data.customerId} data={data.equipment} onDataChanged={onEquipmentDataChanged}></EquipmentList>
                        </Col></Row></Container>
                    </Tab>
                    <Tab eventKey="notes" title="Σημειώσεις" style={{ minHeight: '200px' }}>
                        <Container className="px-3">
                            <Row className="g-2 my-1">
                                <Col>
                                    <FloatingLabel controlId="floatingNotes" label="Σημειώσεις">
                                        <Form.Control as="textarea" rows={4} style={{minHeight: "150px"}} placeholder="" value={data.notes}
                                            onChange={(e) => setData((p) => ({ ...p, notes: e.target.value }))} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Container>
                    </Tab>
                    <Tab eventKey="old" title="Παλιές Σημειώσεις" style={{ minHeight: '200px' }}>
                        <Container className="px-3">
                            <Row className="g-2 my-1"><Col>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td width={"20%"}>
                                            <strong>Σημειώσεις:</strong>
                                        </td>
                                        <td width={"80%"}>
                                            {data.pastWorks}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Μηχανήματα:</strong>
                                        </td>
                                        <td>
                                            {data.pastEquipment}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </Col></Row>
                        </Container>
                    </Tab>
                </Tabs>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
                <Button variant="secondary" className="mx-1" onClick={onCancelClick}>Ακύρωση</Button>
                <Button variant="primary" className="mx-1" onClick={() => onSaveClick(data)}>Αποθήκευση</Button>
            </Card.Footer>
        </Card>
    );
};