import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Tabs, Tab, Form, Button, FloatingLabel, Badge } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _, { set } from "lodash";

export default function CustomerTaskDetail(props) {
  const [data, setData] = useState(props.task)
  const [dataEquipment, setDataEquipment] = useState(props.equipment)
  const [dataServiceCrews, setDataServiceCrews] = useState(props.serviceCrews)
  const [dataServiceKinds, setDataServiceKinds] = useState(props.serviceKinds)
  const [dataReferrals, setDataReferrals] = useState(props.referrals)

  const onCancelClick = props.onCancelClick;
  const onSaveClick = props.onSaveClick;

  useEffect(() => { setData(props.task); console.log(props.task); }, [props.task])
  useEffect(() => { setDataEquipment(props.equipment) }, [props.equipment])
  useEffect(() => { setDataServiceCrews(props.serviceCrews) }, [props.serviceCrews])
  useEffect(() => { setDataServiceKinds(props.serviceKinds) }, [props.serviceKinds])
  useEffect(() => { setDataReferrals(props.referrals) }, [props.referrals])

  const onSubtaskChange = function(customerEquipmentId, serviceKindId, checked) {
    let subtasks = _.cloneDeep(data.subtasks)
    if (checked && !subtasks.some(s => s.customerEquipmentId === customerEquipmentId && s.serviceKindId === serviceKindId)) {
      subtasks.push({ customerEquipmentId: customerEquipmentId, serviceKindId: serviceKindId })
      setData({ ...data, subtasks: subtasks })
    }
    else if (!checked && subtasks.some(s => s.customerEquipmentId === customerEquipmentId && s.serviceKindId === serviceKindId)) {
      setData({ ...data, subtasks: data.subtasks.filter(f => !(f.customerEquipmentId === customerEquipmentId && f.serviceKindId === serviceKindId))})
    }
  }

  const onReminderIsCompleteChange = function(checked) {
    if (data.reminder == null) {
      setData({...data, reminder: { isComplete: checked, reminderId: -1, reminderOn: null }})
    }
    else {
      setData({...data, reminder: { ...data.reminder, isComplete: checked }})
    }
  }

  useEffect(() => {
    // if reminder is new and unchecked then delete it!
    if (data.reminder?.reminderId == -1 && data.reminder?.isComplete == false) {
      setData({...data, reminder: null})
    }
  }, [data])

  return (
    <Card>
      <Card.Header>
        <h4>Εργασία</h4>
      </Card.Header>
      <Card.Body>
        <Row className="g-2 my-1">
          <Col md={12} xs={{order: "last"}}>
            <FloatingLabel controlId="floatingNotes" label="Σημειώσεις">
              <Form.Control as="textarea" rows={3} style={{minHeight: "100px"}} value={data.notes} onChange={(e) => setData({ ...data, notes: e.target.value })} />
            </FloatingLabel>
          </Col>
          <Col md={4}>
            <div className="custom-floating">
              <DatePicker selected={new Date(data.scheduledOn)} showicon dateFormat={"d/M/yyyy"}
                onChange={(e) => setData({...data, scheduledOn: e})}></DatePicker>
              <label>Ημερομηνία</label>
            </div>
          </Col>
          <Col md={4}>
            <FloatingLabel controlId="floatingMobilePhoneNumber" label="Συνεργείο">
              <Form.Select value={data.serviceCrewId}
                onChange={(e) => setData({ ...data, serviceCrewId: +e.target.value })}>
                  <option value={null}></option>
                  {dataServiceCrews.map(crew => (
                  <option key={crew.serviceCrewId} value={crew.serviceCrewId}>{crew.name}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={4}>
            <FloatingLabel controlId="floatingMobilePhoneNumber" label="Εταιρεία">
              <Form.Select value={data.referralId}
                onChange={(e) => setData({ ...data, referralId: +e.target.value })}>
                  <option value={null}></option>
                  {dataReferrals.map(referral => (
                  <option key={referral.referralId} value={referral.referralId}>{referral.name}</option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="g-2 my-1">
          <Col md={12}>
            <table className="table" style={{ border: "1px solid #ced4da", borderRadius: "0.375rem" }}>
              <tbody>
              {dataEquipment.map(equipment => (
                <tr key={equipment.customerEquipmentId}>
                  <td>{equipment.name}</td>
                  <td>{dataServiceKinds.filter(f => f.equipmentKinds.map(kind => kind.equipmentKindId).includes(equipment.equipmentKindId)).map(svc => (
                    <div className="mx-2 form-check" style={{display: "inline-block"}} key={"div." + equipment.customerEquipmentId + "." + svc.serviceKindId}>
                      <input type="checkbox" className="form-check-input"
                        key={"subtask." + equipment.customerEquipmentId + "." + svc.serviceKindId}
                        checked={data.subtasks.some(s => s.customerEquipmentId === equipment.customerEquipmentId && s.serviceKindId === svc.serviceKindId)}
                        onChange={(e) => onSubtaskChange(equipment.customerEquipmentId, svc.serviceKindId, e.target.checked)}></input>
                        <label className="form-check-label">{svc.name}</label>
                    </div>
                  ))}</td>
                </tr>
              ))}
              </tbody>
            </table>
            
          </Col>
        </Row>
        <Row className="g-2 my-1">
          <Col md={4}>
            <FloatingLabel controlId="floatingPrice" label="Τιμή">
              <Form.Control placeholder="Τιμή" value={data.price} onChange={(e) => setData({ ...data, price: +e.target.value })} 
                onKeyDown={(e) => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); }}} />
            </FloatingLabel>
          </Col>
          <Col md={2}>
            <Form.Check checked={data.invoice} onChange={(e) => setData({ ...data, invoice: e.target.checked })} label="Τιμολόγιο" />
          </Col>
          <Col md={4}>
            { ((data.reminder !== null) && (data.reminder?.reminderOn !== null)) 
              ?
              <div className="custom-floating">
                <DatePicker selected={new Date(data.reminderOn)} showicon dateFormat={"d/M/yyyy"} disabled
                  onChange={(e) => setData({...data, reminderOn: e})}></DatePicker>
                <label>Υπενθύμιση</label>
              </div>
              :
              <div className="custom-floating">
                <DatePicker selected={new Date(data.reminderOn)} showicon dateFormat={"d/M/yyyy"}
                  onChange={(e) => setData({...data, reminderOn: e})}></DatePicker>
                <label>Υπενθύμιση</label>
              </div>
            }
          </Col>
          <Col md={2}>
            { (data.reminder == null || data.reminder?.reminderOn == null) 
            ? <Form.Check checked={data.reminder?.isComplete ?? false} onChange={(e) => onReminderIsCompleteChange(e.target.checked)} label="Ολοκληρωμένη Υπενθύμιση" />
            : <span></span>
            }
          </Col>
        </Row>
      </Card.Body>
      { ((data.reminder !== null) && (data.reminder?.reminderOn !== null))
        ? 
        <Card.Body style={{backgroundColor: '#f8f9fa'}}>
          <Row className="g-2 my-1">
            <Col md={12}>
              <h5 className="mt-0 mb-2">Υπενθύμιση</h5>
            </Col>
            <Col md={4}>
              <div className="custom-floating">
                <DatePicker selected={new Date(data.reminder?.reminderOn ?? data.reminderOn)} showicon dateFormat={"d/M/yyyy"}
                  onChange={(e) => setData({...data, reminder: { ...data.reminder, reminderOn: e}})}></DatePicker>
                <label>Νέα Υπενθύμιση</label>
              </div>
            </Col>
            <Col md={2}>
              <Form.Check checked={data.reminder?.isComplete} onChange={(e) => setData({...data, reminder: { ...data.reminder, isComplete: e.target.checked}})} label="Ολοκληρωμένη Υπενθύμιση" />
            </Col>
            <Col md={6}>
             <FloatingLabel controlId="floatingNotes" label="Σημειώσεις">
                <Form.Control as="textarea" rows={2} style={{minHeight: "50px"}} placeholder="" value={data.reminder?.notes ?? ''}
                    onChange={(e) => setData({...data, reminder: { ...data.reminder, notes: e.target.value}})} />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
        : null
      }
      <Card.Footer className="d-flex justify-content-end">
        <Button variant="secondary" className="mx-1" onClick={onCancelClick}>Ακύρωση</Button>
        <Button variant="primary" className="mx-1" onClick={() => onSaveClick(data)}>Αποθήκευση</Button>
      </Card.Footer>
    </Card>
  );
};