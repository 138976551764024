import { useState, useEffect, props } from 'react';
import Popup from 'reactjs-popup';

import { CustomerApi, ReminderApi } from '../../apis/CustomerApi';
import CustomerDetail from '../../components/CustomerDetail';
import ReminderDetail from '../reminder/ReminderDetail';
import DataTable from '../../components/data/DataTable';

function CalendarList(props) {
  const [openCustomer, setOpenCustomer] = useState(false);
  const [openReminder, setOpenReminder] = useState(false);
  const [month, setMonth] = useState({});
  const [dataCurrentReminder, setDataCurrentReminder] = useState({})
  const [dataCurrentCustomer, setDataCurrentCustomer] = useState({})
  const [dataReminderList, setDataReminderList] = useState([])

  const onCloseCustomer = () => setOpenCustomer(false);
  const onCloseReminder = () => setOpenReminder(false);

  useEffect(() => {
    async function fetchDataReminderList(month) {
      let data = await ReminderApi.getByMonth(month)
      setDataReminderList(data)
    }

    setMonth(props.month)
    fetchDataReminderList(props.month)
    console.log("requesting month " + props.month)
  }, [props.month]);

  useEffect (() => {
    console.log(dataReminderList);
  }, [dataReminderList])

  const onCustomerListRowClick = async function(row, column) {
    if (column == 'fullName') {
      setDataCurrentCustomer(await CustomerApi.get(row.original.customerId));
      setOpenCustomer(true);
    }
    else 
    {
      if (row.original.reminderId > 0) {
        setDataCurrentReminder(await ReminderApi.get(row.original.reminderId))
      }
      else {
        setDataCurrentReminder(await ReminderApi.getByCustomerTaskId(row.original.customerTaskId))
      }
      setOpenReminder(true)
    }
  }

  async function onCustomerDetailSaveClick(customer) {
    let res = await CustomerApi.update(customer.customerId, customer);
    setDataCurrentCustomer({});
    setOpenCustomer(false);
  }

  function onCustomerDetailCancelClick() {
    setDataCurrentCustomer({});
    setOpenCustomer(false);
  }

  async function onReminderDetailSaveClick(reminder) {
    let res = await ReminderApi.createOrUpdate(reminder);
    setDataReminderList(dataReminderList.map((row) => {
      return row.customerTaskId === reminder.customerTaskId ? reminder : row;
    }));
    setDataCurrentReminder({});
    setOpenReminder(false);
  }

  function onReminderDetailCancelClick() {
    setDataCurrentReminder({});
    setOpenReminder(false);
  }

  const columns = [
    {key: 'reminderOn', header: 'Υπενθύμιση', width: '15%', format: 'date'},
    {key: 'isComplete', header: '', width: '5%', format: 'check'},
    {key: 'notes', header: 'Σημειώσεις', width: '45%' },
    {key: 'fullName', header: 'Καρτέλα Πελάτη', width: '30%', openable: true}];

  return (
    <>
      <DataTable data={dataReminderList} columns={columns} onRowClick={onCustomerListRowClick}  showSearch={false} showAddButton={false}>
      </DataTable>
      <Popup open={openCustomer} onClose={onCloseCustomer} modal closeOnDocumentClick={false}>
        <CustomerDetail 
          customer={dataCurrentCustomer}
          onSaveClick={onCustomerDetailSaveClick}
          onCancelClick={onCustomerDetailCancelClick}>
        </CustomerDetail>
      </Popup>
      <Popup open={openReminder} onClose={onCloseReminder} modal closeOnDocumentClick={true}>
        <ReminderDetail
          reminder={dataCurrentReminder}
          onSaveClick={onReminderDetailSaveClick}
          onCancelClick={onReminderDetailCancelClick}>
        </ReminderDetail>
      </Popup>
    </>
  );
}

export default CalendarList;
