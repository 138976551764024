import { useState, useEffect } from 'react';
import { Form,  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerApi, ServiceApi } from '../../apis/CustomerApi';
import _ from 'lodash';
import Popup from 'reactjs-popup';
import CustomerTaskDetail from './CustomerTaskDetail';

function CustomerTaskList(props) {
  const [data, setData] = useState(props.data);
  const [dataEquipment, setDataEquipment] = useState(props.equipment)
  const [rowEdited, setRowEdited] = useState({});
  const [rowAdded, setRowAdded] = useState(0);
  const [dataHasChange, setDataHasChange] = useState(false);
  const [dataServiceKinds, setDataServiceKinds] = useState([]);
  const [dataServiceCrews, setDataServiceCrews] = useState([]);
  const [dataReferrals, setDataReferrals] = useState([]);
  const [taskDetailOpen, setTaskDetailOpen] = useState(false);

  const onDataChanged = props.onDataChanged;

  useEffect(() => {
    setData(props.data)
  }, [props.data]);

  useEffect(() => {
    setDataEquipment(props.equipment)
  }, [props.equipment]);

  useEffect(() => {
    async function getServiceKinds() {
      setDataServiceKinds(await ServiceApi.getServiceKinds())
    }
    async function getServiceCrews() {
      setDataServiceCrews(await ServiceApi.getServiceCrews())
    }
    async function getReferrals() {
      setDataReferrals(await CustomerApi.getReferrals())
    }
    getServiceKinds()
    getServiceCrews()
    getReferrals()
  }, [])

  useEffect(() => {
    if (dataHasChange) {
      onDataChanged(data)
      console.log(data)
    }
    setDataHasChange(false)
  }, [dataHasChange])

  const onRowEdit = function(id) {
    setRowEdited(_.cloneDeep(data.find(row => {
      return row.customerTaskId === id
    })))
    setTaskDetailOpen(true);
  }

  const onRowAdd = function() {
    let minId = Math.min(...data.map(row => row.customerTaskId))
    if (minId < 0) { minId = minId-1 } else { minId = -1 }
    const newrow = { customerTaskId: minId, scheduledOn: new Date(), reminderOn: getReminderDate(), subtasks: [], reminder: null }
    setData([...data, newrow])
    setRowAdded(minId)
    setRowEdited(newrow)
    setTaskDetailOpen(true);
  };

  const onRowDelete = function(id) {
    setData(data.filter(f => f.customerTaskId !== id));
  }

  const onCancelClick = function() {
    setRowEdited({})
    setTaskDetailOpen(false)
  }

  const onSaveClick = function(task) {
    setData(data.map((row) => {
      return row.customerTaskId === rowEdited.customerTaskId ? task : row;
    }));
    console.log(task.subtasks)
    setRowEdited({})
    setTaskDetailOpen(false)
    setDataHasChange(true)
  }

  const formatDate = function(datestring) {
    let val = new Date(datestring);
    return val;
  }

  const getServiceCrew = function(id) {
    return dataServiceCrews.find(f => f.serviceCrewId === id)?.name;
  }

  const getReminderDate = function() {
    let dt = new Date()
    dt = new Date(dt.setMonth(dt.getMonth() + 11))
    return dt
  }

  const getCustomerEquipment = function(id) {
    return dataEquipment.find(f => f.customerEquipmentId === id)?.name ?? "[" + id + "]";
  }
  const getServiceKind = function(id) {
    return dataServiceKinds.find(f => f.serviceKindId === id)?.name;
  }
  
  return (
    <>
      <table className='table'>
        <thead>
          <tr>
            <th width="15%">Ημ/νία</th>
            <th width="15%">Τεχνικός</th>
            <th width="55%">Εργασίες</th>
            <th width="10%">Τιμή</th>
            <th width="15%">Υπενθύμιση</th>
            <th width="0%"></th>
            <th width="0%"></th>
          </tr>
        </thead>
        <tbody>{(data && data.length > 0) ? data.map(row => (
            <tr key={row.customerTaskId}>
              <td>{formatDate(row.scheduledOn).toLocaleDateString("el-gr")}</td>
              <td>{getServiceCrew(row.serviceCrewId)}</td>
              <td>
                { /* let unique = */ [...new Set(row.subtasks.map(m => m.customerEquipmentId))].map(uniqueeq => (
                  <span className="badge text-dark me-2" style={{backgroundColor: "#ddd"}} key={uniqueeq}>
                    <span className="pe-1">{getCustomerEquipment(uniqueeq)}</span>
                    {row.subtasks.filter(f => f.customerEquipmentId === uniqueeq).map(subtask => (
                      <span className="text-dark px-1" style={{backgroundColor: "#f6f6f6"}} key={subtask.customerTaskSubtaskId}>{getServiceKind(subtask.serviceKindId)}</span>
                    ))}
                  </span>
                )) }
                {row.notes}
              </td>
              <td>{row.price}</td>
              <td>{formatDate(row.reminder?.reminderOn ?? row.reminderOn).toLocaleDateString("el-gr")}</td>
              <td>{(row.reminder?.isComplete ?? false) ? <FontAwesomeIcon icon="check" /> : null}</td>
              <td nowrap="nowrap">
                <button className="btn btn-sm btn-light" onClick={() => onRowEdit(row.customerTaskId)}><FontAwesomeIcon icon="pencil"></FontAwesomeIcon></button>
                <button className="btn btn-sm btn-light" onClick={() => onRowDelete(row.customerTaskId)}><FontAwesomeIcon icon="trash"></FontAwesomeIcon></button>
              </td>
            </tr>
          )) : <tr className='d-none'><td></td></tr>}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={7} className="text-end">
              <button className="btn btn-sm btn-primary nowrap" onClick={onRowAdd}><FontAwesomeIcon icon="plus"></FontAwesomeIcon></button>
            </td>
          </tr>
        </tfoot>
      </table>
      <Popup modal open={taskDetailOpen} className="popup-md">
        <CustomerTaskDetail 
          task={rowEdited} 
          equipment={dataEquipment}
          serviceCrews={dataServiceCrews}
          serviceKinds={dataServiceKinds}
          referrals={dataReferrals}
          onCancelClick={onCancelClick}
          onSaveClick={onSaveClick}>

        </CustomerTaskDetail>
      </Popup>
    </>
  );
}

export default CustomerTaskList;
