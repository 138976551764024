import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from './App';
import CalendarApp from './features/calendar/CalendarApp';
import moment from 'moment';
import StatisticsApp from './features/statistics/StatisticsApp';

// const router = createBrowserRouter([
//   {
//     path: "/hello",
//     element: <div>Hello world!</div>,
//   },
//   {
//     path: "/tmp20230205",
//     element: <App />
//   }
// ]);

const calendarcur = moment().format("YYYYMM")

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  //<React.StrictMode>
  //<App />
  //<RouterProvider router={router} />
  //</React.StrictMode>
  //
  //Error:
  //<Route path="/calendar" exact element={<Navigate to={`/calendar/${calendarcur}`} />}>
  //<Route path=":month" element={<CalendarApp/>} />
  //</Route>
  <BrowserRouter>
    <Routes>  
      <Route path="/" element={<Navigate to={`/customers`} />} />
      <Route path="/customers" element={<App/>} />
      <Route path="/calendar" element={<Navigate to={`/calendar/${calendarcur}`} />} />
      <Route path="/calendar/:month" element={<CalendarApp/>} />
      <Route path="/statistics" element={<StatisticsApp/>} />
      <Route path="hello" element={<div>Hello World!</div>} />
    </Routes>
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
