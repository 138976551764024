import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { CustomerApi } from '../../apis/CustomerApi';
import CustomerListTable from '../../features/customer/CustomerListTable';
import CustomerDetail from '../../components/CustomerDetail';

function CustomerApp() {
  const [open, setOpen] = useState(false);
  const [dataCurrentCustomer, setDataCurrentCustomer] = useState({})
  const [dataCustomerList, setDataCustomerList] = useState([])


  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    async function getCustomerList() {
      setDataCustomerList(await CustomerApi.getAll());
    }
    getCustomerList();
  }, []);

  const onCustomerListRowClick = async function(row) {
    setDataCurrentCustomer(await CustomerApi.get(row.original.customerId));
    setOpen(true);
  }

  const onCustomerListAddClick = function() {
    console.log("add")
    setDataCurrentCustomer({ customerId: -1, equipment: [], tasks: []})
    setOpen(true);
  }

  async function onCustomerDetailSaveClick(customer) {
    if (customer.customerId === -1) {
      //Add
      let res = await CustomerApi.create(customer);
      setDataCustomerList([...dataCustomerList, customer])
      setDataCurrentCustomer({});
      setOpen(false);
    }
    else {
      let res = await CustomerApi.update(customer.customerId, customer);
      setDataCustomerList(dataCustomerList.map((row) => {
        return row.customerId === customer.customerId ? customer : row;
      }));
      setDataCurrentCustomer({});
      setOpen(false);
    }
  }

  function onCustomerDetailCancelClick() {
    setDataCurrentCustomer({});
    setOpen(false);
  }

  //#region Data Calls

  

  // async function getCustomer(id) {

  // }

  //#endregion

  return (
    <>
      <CustomerListTable 
        data={dataCustomerList} 
        onRowClick={onCustomerListRowClick} 
        onAddClick={onCustomerListAddClick}>

      </CustomerListTable>
      <Popup open={open} onClose={onCloseModal} modal closeOnDocumentClick={false}>
        <CustomerDetail 
          customer={dataCurrentCustomer}
          onSaveClick={onCustomerDetailSaveClick}
          onCancelClick={onCustomerDetailCancelClick}>

        </CustomerDetail>
      </Popup>
    </>
  );
}

export default CustomerApp;
