import { useState, useEffect, props } from 'react';
import Popup from 'reactjs-popup';

import { CustomerApi, ReminderApi } from '../../apis/CustomerApi';
import CustomerDetail from '../../components/CustomerDetail';
import ReminderDetail from '../reminder/ReminderDetail';
import DataTable from '../../components/data/DataTable';

function CustomerListFromSubtaskCount(props) {
  const [customerData,setCustomerData] = useState(props.customers)
  const [openCustomer, setOpenCustomer] = useState(false);
  const [dataCurrentCustomer, setDataCurrentCustomer] = useState({})

  useEffect(() => {
    setCustomerData(props.customers)
  }, [props.customers])

  const onCloseCustomer = () => setOpenCustomer(false);

  async function onRowClick(row) {
    setDataCurrentCustomer(await CustomerApi.get(row.original.customerId));
    setOpenCustomer(true);
  }
  
  async function onCustomerDetailSaveClick(customer) {
    let res = await CustomerApi.update(customer.customerId, customer);
    setDataCurrentCustomer({});
    setOpenCustomer(false);
  }

  function onCustomerDetailCancelClick() {
    setDataCurrentCustomer({});
    setOpenCustomer(false);
  }

  const columns = [
    {key: 'scheduledOn', header: 'Προγραμματισμός', width: '30%', format: 'date'},
    {key: 'fullName', header: 'Ονοματεπώνυμο', width: '30%'},
    {key: 'notes', header: 'Σχόλια Εργασίας', width: '40%'}]

  return (
    <>
      <DataTable data={customerData} columns={columns} onRowClick={onRowClick} showAddButton={false}>
      </DataTable>
      <Popup open={openCustomer} onClose={onCloseCustomer} modal closeOnDocumentClick={false}>
        <CustomerDetail 
          customer={dataCurrentCustomer}
          onSaveClick={onCustomerDetailSaveClick}
          onCancelClick={onCustomerDetailCancelClick}>
        </CustomerDetail>
      </Popup>
    </>
  );  
}

export default CustomerListFromSubtaskCount