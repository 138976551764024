import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Tabs, Tab, Form, Button, FloatingLabel, Badge } from 'react-bootstrap';
import _ from "lodash";
import DatePicker from "react-datepicker";

export default function ReminderDetail(props) {
  const [data, setData] = useState(props.reminder)
  const [dataCustomer, setDataCustomer] = useState(props.customer)
  const onSaveClick = props.onSaveClick
  const onCancelClick = props.onCancelClick

  useEffect(() => {
    console.log(props.reminder)
    setData(props.reminder);
    
  }, [props.reminder])

  useEffect(() => {
    setDataCustomer(props.customer);
  }, [props.customer])

  return (
    <Card>
      <Card.Header>
        <h4 className="my-1">Υπενθύμιση</h4>
      </Card.Header>
      <Card.Body>
        <Row className="g-2 my-1">
          <Col md={12} xs={{order: "last"}}>
            <FloatingLabel controlId="floatingNotes" label="Σημειώσεις">
              <Form.Control as="textarea" rows={4} style={{minHeight: "150px"}} placeholder="" value={data.notes ?? ''}
                onChange={(e) => setData((p) => ({ ...p, notes: e.target.value }))} />
            </FloatingLabel>
          </Col>
          <Col md={6}>
            <div className="custom-floating">
              <DatePicker selected={new Date(data.reminderOn)} showicon dateFormat={"d/M/yyyy"}
                onChange={(e) => setData({...data, reminderOn: e})}></DatePicker>
              <label>Ημερομηνία</label>
            </div>
          </Col>
          <Col md={6}>
            <Form.Check checked={data.isComplete ?? false} onChange={(e) => setData({ ...data, isComplete: e.target.checked })} label="Ολοκληρώθηκε" />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button variant="secondary" className="mx-1" onClick={onCancelClick}>Ακύρωση</Button>
        <Button variant="primary" className="mx-1" onClick={() => onSaveClick(data)}>Αποθήκευση</Button>
      </Card.Footer>
    </Card>
  )

}