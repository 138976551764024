import { useState, useEffect } from 'react';
import DataTable from '../../components/data/DataTable';

function CustomerListTable(props) {
    const [data, setData] = useState(props.data);
    const onRowClick = props.onRowClick;
    const onAddClick = props.onAddClick;

    useEffect(() => {
        setData(props.data)
        console.log(props.data)
    }, [props.data]);

    const columns = [
        {key: 'fullName', header: 'Ονοματεπώνυμο', width: '60%'},
        {key: 'city', header: 'Πόλη', width: '10%'},
        {key: 'phoneNumber', header: 'Τηλέφωνο', width: '10%'},
        {key: 'mobilePhoneNumber', header: 'Κινητό', width: '10%'},
        {key: 'codes', header: '', width: '0'}];
    
    return (
        <DataTable data={data} columns={columns} onRowClick={onRowClick} onAddClick={onAddClick} showSearch={true} showAddButton={true}>
        </DataTable>
    );  
}

export default CustomerListTable;