import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Form from "react-bootstrap/Form";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Badge } from "react-bootstrap";
import { ReminderApi } from "../../apis/CustomerApi";

export default function Header() {

    const [reminderData, setReminderData] = useState({})

    const getUsername = () => {
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        console.log(userToken)
        return userToken?.userName
    }

    const getReminderData = async () => {
        setReminderData(await ReminderApi.getDue())
    }

    return (
        <Navbar variant="dark" expand="lg" style={{backgroundColor: '#28304e'}}>
            <Container>
                <Navbar.Brand href="#home">
                    Cool & Hot Service &nbsp;
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/calendar">
                            Ημερολόγιο 
                            <span className="badge badge-light">{ reminderData?.ReminderDueCount ?? 0 }</span>
                        </Nav.Link>
                        <Nav.Link href="/customers">Πελατολόγιο</Nav.Link>
                        { ((getUsername() == "thomas")
                        ? <Nav.Link href="/statistics">Στατιστικά</Nav.Link>
                        : null
                        )}
                        <NavDropdown title="Dropdown" id="basic-nav-dropdown" style={{display: 'none'}}>
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Form className="d-flex d-none">
                        <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                        />
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};