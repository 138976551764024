import axios, { AxiosRequestConfig } from 'axios';

const getToken = () => {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
};

function authRequestInterceptor(config) {
  const token = getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const api = axios.create({
    baseURL: "https://coolhotapp.getonline.gr/api/v1/",
    //baseURL: "https://localhost:7002/api/v1/",
})

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    const statusCode = error.response?.status

    // if (error.code === "ERR_CANCELED") {
    //     notification.error({
    //         placement: "bottomRight",
    //         description: "API canceled!",
    //     })
    //     return Promise.resolve()
    // }

    if (statusCode && (statusCode === 401 || statusCode === 403)) {
      console.log('unauthorized!')
      sessionStorage.removeItem('token');
      window.location.reload();
    }

    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
        console.error(error)
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

api.interceptors.request.use(authRequestInterceptor);

// axios.interceptors.response.use(
//   (response) => {
//     return response.data;
//   },
//   (error) => {
//     const message = error.response?.data?.message || error.message;
//     useNotificationStore.getState().addNotification({
//       type: 'error',
//       title: 'Error',
//       message,
//     });

//     return Promise.reject(error);
//   }
// );
